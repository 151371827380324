<template>
	<div :class="containerClass" @click="onDocumentClick">
		<div class="layout-wrapper-content">
			<AppTopBar ref="myChild" :topbarMenuActive="topbarMenuActive" :profileMode="profileMode" :horizontal="layoutMode === 'horizontal'" :activeTopbarItem="activeTopbarItem"
			@menubutton-click="onMenuButtonClick" @topbar-menubutton-click="onTopbarMenuButtonClick" @topbar-item-click="onTopbarItemClick"></AppTopBar>

			<transition name="layout-menu-container">
				<div :class="menuClass" @click="onMenuClick" v-show="isMenuVisible()">
					<div class="menu-scroll-content">
						<AppInlineProfile v-if="profileMode === 'inline' && layoutMode !== 'horizontal'" @profile-click="onProfileClick" :expanded="profileExpanded"></AppInlineProfile>

						<AppMenu :model="menu" :layoutMode="layoutMode" :active="menuActive" :mobileMenuActive="staticMenuMobileActive" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"></AppMenu>
					</div>
				</div>
			</transition>

			<div class="layout-main">
				<router-view />
				<Sidebar v-model:visible="visibleRight" :baseZIndex="1000" position="right">
					<h4>Notificaciones</h4>
					<ul class="layout-menu fadeInDown">
							<li role="menuitem" v-for="noti in notifications" :key="noti.idNotification">
								<router-link :to="noti.sUrlLink" @click="visibleRight=false">
									<i v-if="noti.sIcon != ''" class="pi pi-fw pi-calendar-plus"></i>
									<span> <div v-html="noti.sNotification"></div></span>
								</router-link>
								<hr/>
							</li>
							
					</ul>

				</Sidebar>
			</div>

			<AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" :darkMenu="darkMenu" @menu-color-change="onMenuColorChange"
						:profileMode="profileMode" @profile-mode-change="onProfileModeChange" :layout="layoutColor" :layouts="layoutColors" @layout-color-change="onLayoutColorChange"
						:theme="theme" :themes="themeColors" @theme-change="onThemeChange"  :layoutScheme='layoutScheme' @layout-scheme-change='changeLayoutScheme'></AppConfig>

			<div class="layout-mask"></div>

			<AppFooter />
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import AppTopBar from './AppTopbar.vue';
import AppConfig from './AppConfig.vue';
import AppInlineProfile from './AppInlineProfile.vue';
import AppMenu from './AppMenu.vue';
import AppFooter from './AppFooter.vue';
import EventBus from './event-bus';
import { ref } from 'vue';
const myChild = ref(null);			
let  sseClient
export default {
    props: {
        theme: String,
        layoutColor: String,
        layoutScheme: String,
        darkMenu: Boolean,
    },
    data() {
        return {
			notifications: null,
			visibleRight: false,
			layoutMode: 'static',
			profileMode: 'inline',
			overlayMenuActive: false,
			staticMenuDesktopInactive: false,
			staticMenuMobileActive: false,
			topbarMenuActive: false,
			activeTopbarItem: null,
			rotateMenuButton: false,
			menu : [],
			menuActive: false,
			profileExpanded: false,
			themeColors: [
                {name: 'Blue', file: 'blue', color: '#0d6efd'},
                {name: 'Indigo', file: 'indigo', color: '#6610f2'},
                {name: 'Purple', file: 'purple', color: '#6f42c1'},
                {name: 'Pink', file: 'pink', color: '#d63384'},
                {name: 'Orange', file: 'orange', color: '#fd7e14'},
                {name: 'Yellow', file: 'yellow', color: '#ffc107'},
                {name: 'Green', file: 'green', color: '#198754'},
                {name: 'Teal', file: 'teal', color: '#20c997'},
                {name: 'Cyan', file: 'cyan', color: '#0dcaf0'},
			],
			layoutColors: [
                {name: 'Blue', file: 'blue', color: '#0d6efd'},
                {name: 'Indigo', file: 'indigo', color: '#6610f2'},
                {name: 'Purple', file: 'purple', color: '#6f42c1'},
                {name: 'Pink', file: 'pink', color: '#d63384'},
                {name: 'Orange', file: 'orange', color: '#fd7e14'},
                {name: 'Yellow', file: 'yellow', color: '#ffc107'},
                {name: 'Green', file: 'green', color: '#198754'},
                {name: 'Teal', file: 'teal', color: '#20c997'},
                {name: 'Cyan', file: 'cyan', color: '#0dcaf0'},
			],
        }
    },
	mounted(){
		{
			this.getNotifications()
			console.log('Brnaches: ' + localStorage.idsu)
			sseClient = this.$sse.create({
				url: apiURL + 'SSE/message',
				format: '',
				withCredentials: false,
				polyfill: true,
			});

			sseClient.on('error', (e) => {
				console.error('lost connection or failed to parse!', e);
    		});

    		sseClient.on('transfer', this.handleTransfer);
			sseClient.on('promotions', this.handlePromo);
			sseClient.on('restock', this.handleRestock);
			sseClient.connect()
			.then(sse => {
				console.log('We\'re connected!: ' + JSON.stringify(sse));


			})
			.catch((err) => {
				// When this error is caught, it means the initial connection to the
				// events server failed.  No automatic attempts to reconnect will be made.
				console.error('Failed to connect to server', err);
			});    

			// Catch any errors (ie. lost connections, etc.)
			sseClient.on('error', (e) => {
			console.error('lost connection or failed to parse!', e);

			// If this error is due to an unexpected disconnection, EventSource will
			// automatically attempt to reconnect indefinitely. You will _not_ need to
			// re-add your handlers.
			});


			if(JSON.parse(localStorage.getItem("dataPerson")).idRol == 1){
				this.menu =
				[
/* 					{		
						label: 'Favorites', icon: 'pi pi-fw pi-home',
						items: [
							{
								label: 'Dashboards', icon: 'pi pi-fw pi-home',
								items: [
									{label: 'Generic', icon: 'pi pi-fw pi-home', to: '/dashboards/generic'},
									{label: 'Banking', icon: 'pi pi-fw pi-money-bill', to: '/dashboards/dashboard_banking'},
								]
							}
						]
					}, */
					{
						label: 'Catálogos', icon: 'pi pi-fw pi-star',
						items: [
							{label: 'Sucursales', icon: 'pi pi-fw pi-id-card', to: '/branches'},
							{label: 'Marcas', icon: 'pi pi-fw pi-check-square', to: '/brands'},
							{label: 'Categorías', icon: 'pi pi-fw pi-bookmark', to: '/categories'},
							{label: 'Clientes', icon: 'pi pi-fw pi-exclamation-circle', to: '/customer'},
							{label: 'Proveedores', icon: 'pi pi-fw pi-mobile', to: '/suppliers'},
							{label: 'Productos', icon: 'pi pi-fw pi-table', to: '/products'},
							//{label: 'List', icon: 'pi pi-fw pi-list', to: '/list'},
							//{label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree'},
							//{label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel'},
							//{label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay'},
							//{label: 'Media', icon: 'pi pi-fw pi-image', to: '/media'},
							//{label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menus'},
							//{label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages'},
							//{label: 'File', icon: 'pi pi-fw pi-file', to: '/file'},
							//{label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart'},
							//{label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc'},
						]
					},
					{
						label: 'Procesos', icon: 'pi pi-fw pi-briefcase',
						items: [
							{label: 'Compras', icon: 'pi pi-fw pi-pencil', to: '/purchases'},
							{label: 'Traspasos', icon: 'pi pi-fw pi-calendar-plus', to: '/transfer'},
							{label: 'Ajuste Inv. Entrada', icon: 'pi pi-fw pi-directions', to: '/stockAdjustment'},
							{label: 'Ajuste Inv. Salida', icon: 'pi pi-fw pi-directions-alt', to: '/stockOut'},
							{label: 'Ventas', icon: 'pi pi-fw pi-dollar', to: '/sales'},
							{label: 'Admin. Promociones', icon: 'pi pi-fw pi-globe', to: '/PromoCampaign'},
							{label: 'Cierres de venta', icon: 'pi pi-fw pi-chart-line', to: '/closeCashList'},
							{label: 'Sol. de Facturas', icon: 'pi pi-fw pi-money-bill', to: '/billingRequestList'},
							{label: 'Reportes', icon: 'pi pi-fw pi-sitemap', to: '/reportes'},
							{label: 'Rastrear IMEI', icon: 'pi pi-fw pi-search', to: '/ImeiHistory'},
						]
					},
					{
						label: 'Administración', icon: 'pi pi-fw pi-address-book',
						items: [
							{label: 'Caja Fuerte', icon: 'pi pi-window-maximize', to: '/adminRentalManage'},
							{label: 'Ahorros', icon: 'pi pi-window-minimize', to: '/adminSavingManage'},
						]
					}
					
				]
			}
			else if(JSON.parse(localStorage.getItem("dataPerson")).idRol == 2){
				this.menu =
				[
/* 					{		
						label: 'Favorites', icon: 'pi pi-fw pi-home',
						items: [
							{
								label: 'Dashboards', icon: 'pi pi-fw pi-home',
								items: [
									{label: 'Generic', icon: 'pi pi-fw pi-home', to: '/dashboards/generic'},
									{label: 'Banking', icon: 'pi pi-fw pi-money-bill', to: '/dashboards/dashboard_banking'},
								]
							}
						]
					}, */
					{
						label: 'Operaciones', icon: 'pi pi-fw pi-briefcase',
						items: [
						
							{label: 'Recepción de Mercancia', icon: 'pi pi-fw pi-pencil', to: '/receives'},
							{label: 'Punto de venta', icon: 'pi pi-fw pi-dollar', to: '/ticketPanel'},
							{label: 'Historial de ventas', icon: 'pi pi-fw pi-chart-line', to: '/saleHistory'},
							{label: 'Cierres de Venta', icon: 'pi pi-fw pi-money-bill', to: '/sellerCloseCash'},
							{label: 'Promociones', icon: 'pi pi-fw pi-credit-card', to: '/promoViewBranch/0'},
							{label: 'Cambios/Devoluciones', icon: 'pi pi-fw pi-sync', to: '/returns'},
							{label: 'Lista de Precios', icon: 'pi pi-fw pi-list', to: '/priceList'},
/* 							{label: 'Ajuste Inv. Entrada', icon: 'pi pi-fw pi-directions', to: '/stockAdjustment'},
							{label: 'Ajuste Inv. Salida', icon: 'pi pi-fw pi-directions-alt', to: '/stockOut'},
 */							{label: 'Traspasos', icon: 'pi pi-fw pi-calendar-plus', to: '/transfer'},
						]
					},
					{
						label: 'Administración', icon: 'pi pi-fw pi-address-book',
						items: [
							{label: 'Caja Fuerte', icon: 'pi pi-window-maximize', to: '/rentalManage'},
							{label: 'Ahorros', icon: 'pi pi-window-minimize', to: '/savingsManage'},
						]
					}					
				]
				
			}

			this.getBrnachSpecialPermission(1)
			this.getBrnachSpecialPermission(2)
		}
	},
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    methods: {
		async getBrnachSpecialPermission(idSpecialPermission){
			await axios.get(apiURL + 'getBranchSpecialPermission?idBranch=' + localStorage.idsu + '&idSpecialPermission=' + idSpecialPermission, { headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.userToken
				} })
			.then( (response) => {
				if(response.data.bCorrect == true){
					if(idSpecialPermission == 1){
						this.menu[0].items.push({label: 'Ajuste Inv. Entrada', icon: 'pi pi-fw pi-directions', to: '/stockAdjustment'})
					}
					else if(idSpecialPermission == 2){
						this.menu[0].items.push({label: 'Ajuste Inv. Salida', icon: 'pi pi-fw pi-directions-alt', to: '/stockOut'})
					}
				}
			} )
			.catch( ()  => {
				console.log("error")
			} )
		},

		async getNotifications(){
			await axios.get(apiURL + 'Notification/getNotificationsList?idRol='+JSON.parse(localStorage.getItem("dataPerson")).idRol+'&idBranch=' + localStorage.idsu , { headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.userToken
				} })
			.then( (response) => {
				this.notifications = response.data.sNotifications
			} )
			.catch( ()  => {
				console.log("error")
			} )

			this.$refs.myChild.setNotification(this.notifications.length);
			//myChild.setNotification(this.notifications.length);

		},
		handleTransfer(message, lastEventId){
			//console.log('Message: ' + message[0])
			if(message[0].toString() == localStorage.idsu.toString()){
				this.visibleRight = true;
			}
			this.getNotifications()

		},

		handlePromo(message, lastEventId){
			if(JSON.parse(localStorage.getItem("dataPerson")).idRol != 1 && message.toString().split('\n')[0] == 'All'){
				this.visibleRight = true;
			}

			else if(message.toString().split('\n')[0] == localStorage.idsu.toString()){
				this.visibleRight = true;
			}
			this.getNotifications()

		},
		handleRestock(message, lastEventId){
			if(JSON.parse(localStorage.getItem("dataPerson")).idRol == 1 && message.toString().split('\n')[0] == 'Admin'){
				this.visibleRight = true;
				this.getNotifications()
			}


		},

		onDocumentClick() {
			if(!this.topbarItemClick) {
				this.activeTopbarItem = null;
				this.topbarMenuActive = false;
			}

			if(!this.menuClick) {
				if(this.isHorizontal() || this.isSlim()) {
					this.menuActive = false;
					EventBus.emit('reset-active-index');
				}

				this.hideOverlayMenu();
			}

			this.topbarItemClick = false;
			this.menuClick = false;
		},
		onProfileClick(event) {
			this.profileExpanded = !this.profileExpanded;
			if(this.isHorizontal() || this.isSlim()) {
				EventBus.emit('reset-active-index');
			}

			event.preventDefault();
		},
		onMenuClick() {
			this.menuClick = true;
		},
		onMenuItemClick(event) {
			if(!event.item.items) {
				EventBus.emit('reset-active-index');
				this.hideOverlayMenu();
			}
			if(!event.item.items && this.isHorizontal()) {
				this.menuActive = false;
			}
		},
		onRootMenuItemClick() {
			this.menuActive = !this.menuActive;
		},
		onMenuButtonClick(event) {
			this.menuClick = true;

			this.rotateMenuButton = !this.rotateMenuButton;
			this.topbarMenuActive = false;

			if(this.layoutMode === 'overlay') {
				this.overlayMenuActive = !this.overlayMenuActive;
			}
			else {
				if(this.isDesktop())
					this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
				else
					this.staticMenuMobileActive = !this.staticMenuMobileActive;
			}

			event.preventDefault();
		},
		onTopbarMenuButtonClick(event) {
			this.topbarItemClick = true;
			this.topbarMenuActive = !this.topbarMenuActive;
			this.hideOverlayMenu();
			event.preventDefault();
		},
		onTopbarItemClick(event) {
			if(event.item == 'notifications'){
				this.visibleRight = true;	
			}
			
			this.topbarItemClick = true;

			if(this.activeTopbarItem === event.item)
				this.activeTopbarItem = null;
			else
				this.activeTopbarItem = event.item;

			event.originalEvent.preventDefault();
		},
		hideOverlayMenu() {
			this.rotateMenuButton = false;
			this.overlayMenuActive = false;
			this.staticMenuMobileActive = false;
		},
		isDesktop() {
			return window.innerWidth > 1024;
		},
		isHorizontal() {
			return this.layoutMode === 'horizontal';
		},
		isSlim() {
			return this.layoutMode === 'slim';
		},
		isMenuVisible() {
			if (this.isDesktop()) {
				if (this.layoutMode === 'static')
					return !this.staticMenuDesktopInactive;
				else if (this.layoutMode === 'overlay')
					return this.overlayMenuActive;
				else
					return true;
			} else {
				if (this.staticMenuMobileActive)
					return true;
				else if (this.layoutMode === 'overlay')
					return this.overlayMenuActive;
				else
					return true;
			}
		},
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
			this.staticMenuDesktopInactive = false;
			this.overlayMenuActive = false;

			if (this.isHorizontal()) {
				this.profileMode = 'top';
				this.profileMenuActive = false;
			}
		},
		onMenuColorChange(menuColor) {
            this.$emit('menu-color-change', menuColor);
		},
        changeLayoutScheme(scheme) {
            this.$emit('layout-scheme-change', scheme);
        },
		onProfileModeChange(profileMode) {
			this.profileMode = profileMode;
		},
		onLayoutColorChange(layout) {
            this.$emit('layout-change', layout);
		},
		onThemeChange(theme) {
            this.$emit('theme-change', theme);
		}
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
				'menu-layout-static': this.layoutMode !== 'overlay',
				'menu-layout-overlay': this.layoutMode === 'overlay',
				'layout-menu-overlay-active': this.overlayMenuActive,
				'menu-layout-slim': this.layoutMode === 'slim',
				'menu-layout-horizontal': this.layoutMode === 'horizontal',
				'layout-menu-static-inactive': this.staticMenuDesktopInactive,
				'layout-menu-static-active': this.staticMenuMobileActive,
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
			}];
        },
		menuClass() {
			return ['layout-menu-container', {'layout-menu-dark': this.darkMenu}];
		}
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppConfig': AppConfig,
        'AppInlineProfile': AppInlineProfile,
        'AppMenu': AppMenu,
        'AppFooter': AppFooter
    },
}
</script>

<style lang="scss">
@import './App.scss';
html {
        font-size: 12px;
    }
</style>
